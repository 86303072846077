<template>
    <v-container
        class="mx-auto container"
        justify="center"
        align="center"
    >

        <div class="image-wrapper">
            <v-img contain max-width="50vw" max-height="300px" src="@/assets/Logo.jpg" />
        </div>
        <v-form>
            <v-text-field
                v-model="email"
                :rules="[rules.email]"
                label="Email"
                class="email"
                required
            />
            <v-text-field
                v-model="password"
                :rules="[rules.counter]"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Password"
                hint="Max 18 characters"
                value=""
                class="input-group--focused password"
                @click:append="show = !show"
            />
            <div v-if="error" class="error-message">
                {{ error }}
            </div>
            <div class="submit-button-wrapper">
                <v-btn
                    class="white--text"
                    color="#223151"
                    :disabled="shouldDisableSubmit()"
                    @click="submit"
                >
                    submit
                </v-btn>
            </div>
        </v-form>
    </v-container>
</template>

<script>
    import store from '@/store'
    import userService from '@/services/user-service'
    export default {
        data() {
            return {
                show: false,
                rules: {
                    counter: value => value.length <= 20 || 'Max 18 characters',
                    email: value => {
                        return this.validEmailForm(value) || 'Invalid e-mail.'
                    }
                },
                email: '',
                password: '',
                error: null
            }
        },
        methods: {
            validEmailForm(email) {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(email)
            },
            shouldDisableSubmit() {
                if (!this.validEmailForm(this.email) || this.password === '') {
                    return true
                }
                return false
            },
            submit() {
                this.error = null
                userService.login({
                    username: this.email,
                    password: this.password
                }).then(resp => {
                    store.dispatch('login', resp).then(() => this.$router.push('/'))
                }).catch(error => { this.error = error.response.data.message })
            }
        }
    }
</script>

<style scoped>
.error-message {
    display: flex;
    justify-content: center;
    color: red;
    font-weight: bold;
}
.image-wrapper{
    width: 40%;
    margin: 0 auto;
    margin-top: 2vh;
}

.password, .email {
    width: 50%;
    margin: 0 auto;
}
.submit-button-wrapper {
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-self: center;
}
</style>
