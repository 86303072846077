export default {
    state: {
        currentUser: {
            role: localStorage.getItem('role')
        },
        token: localStorage.getItem('api-token')
    },
    mutations: {
        setToken(state, value) {
            state.token = value
        },
        setCurrentUser(state, value) {
            state.currentUser = value
            state.loggedIn = true
        }
    },
    actions: {
        logout({ dispatch }) {
            dispatch('setCurrentUser', null)
            dispatch('setToken', null)
        },
        login({ dispatch }, resp) {
            if (resp.data.token) {
                dispatch('setToken', resp.data.token)
                dispatch('setCurrentUser', {
                    role: resp.data.role,
                    dealerId: resp.data.dealer
                })
            }
        },
        setToken({ commit }, token) {
            if (token) {
                localStorage.setItem('api-token', token)
                commit('setToken', token)
            } else {
                localStorage.removeItem('api-token')
                commit('setToken', null)
            }
        },
        setCurrentUser({ commit }, currentUser) {
            if (currentUser) {
                localStorage.setItem('role', currentUser.role)
                commit('setCurrentUser', currentUser)
            } else {
                localStorage.removeItem('role')
                commit('setCurrentUser', null)
            }
        }
    },
    getters: {
        getToken: state => state.token,
        currentUser: (state) => {
            if (state.currentUser && !state.currentUser.role) {
                return null
            }
            return state.currentUser
        },
        isLoggedIn(state) {
            return state.token !== null
        }
    }
}
