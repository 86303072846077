<template>
    <v-menu
        :close-on-content-click="true"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                dark
                small
                color="#ff0000"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon dark>mdi-delete</v-icon>
            </v-btn>
        </template>
        <v-card>
            <div class="text">Are you sure?</div>
            <v-card-actions class="choices">
                <v-btn class="mx-2" dark small color="#027a00" @click="onDeleteVerification">
                    Yes
                </v-btn>
                <v-btn
                    class="mx-2"
                    dark
                    small
                    color="#ff0000"
                >
                    No
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'DeleteButton',
        methods: {
            onDeleteVerification() {
                this.$emit('delete-entry')
            }
        }
    }
</script>

<style>
.choices {
    display: flex;
    justify-content: space-evenly;
}

.text {
    text-align: center;
}
</style>
