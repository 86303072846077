<template>
    <v-app>
        <v-row class="app" no-gutters>
            <sidebar v-if="isLoggedIn" />
            <router-view :key="$route.path" class="router" />
        </v-row>
    </v-app>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    export default {
        name: 'App',
        components: {
            Sidebar
        },
        data: () => ({
        }),
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            }
        }
    }
</script>

<style>
/*
body {
    margin: 0 !important;
} */

</style>

<style scoped>

.app {
    flex-wrap: nowrap;
}

@media (max-width: 850px) {
.router {
    width: 70%;
}
}
</style>
