<template>
    <v-navigation-drawer
        color="#213050"
        permanent
        absolute
        expand-on-hover
    >
        <v-list
            dark
        >
            <v-list-item class="px-2">
                <v-img src="@/assets/Logo.jpg" />
            </v-list-item>
        </v-list>
        <v-list
            nav
            dense
            dark
        >
            <v-list-item link @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-divider />
            <div v-for="(route, index) of routes" :key="index">
                <v-list-item v-if="route.roles.length === 0 || route.roles.includes(userRole)" :to="route.to" link>
                    <v-list-item-icon>
                        <v-icon>mdi-{{ route.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ route.title }}</v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import store from '@/store'
    export default {
        name: 'Sidebar',
        data() {
            return {
                routes: [
                    {
                        title: 'Articles',
                        to: '/articles',
                        roles: [],
                        icon: 'newspaper-variant-outline'
                    },
                    {
                        title: 'Newspaper',
                        to: '/newspaper',
                        roles: [],
                        icon: 'newspaper-variant'
                    }
                ]
            }
        },
        computed: {
            userRole() {
                return store.getters.currentUser && store.getters.currentUser.role
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>

</style>
