import newsApi from '@/utils/news-api'
export default {
    login(data) {
        return newsApi.post(`login`, data)
    },
    createUser(data) {
        return newsApi.post('user/new', data)
    },
    editUserById(id, data) {
        return newsApi.post(`user/${id}/edit`, data)
    },
    getAllUsers() {
        return newsApi.get(`user/all`)
    },
    getUserById(id) {
        return newsApi.get(`user/${id}`)
    },
    deleteUserById(id) {
        return newsApi.delete(`user/${id}`)
    }
}
