<template>
    <v-container>
        <v-btn class="my-2" dark small color="#027a00" @click="createArticle">
            <v-icon dark>mdi-plus</v-icon>Create
        </v-btn>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="articles"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.title }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.author }}</td>
                    <td @click="handleClick('show', props.item)">{{ formatDate(props.item.articleDate, 'yyyy-MM-DD') }}</td>
                    <td @click="handleClick('show', props.item)">{{ mapTrueFalse(props.item.promotion) }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import articleService from '@/services/article-service'
    import DeleteButton from '@/components/DeleteButton'
    import moment from 'moment'

    export default {
        name: 'TeamRoute',
        components: { DeleteButton },
        data: () => {
            return {
                articles: [],
                headers: [{
                              text: 'Title',
                              value: 'title',
                              align: 'left',
                              sortable: true
                          },
                          { text: 'Author', value: 'author' },
                          { text: 'Article Date', value: 'articleDate' },
                          { text: 'Is Promotion', value: 'promotion' }]
            }
        },
        created() {
            this.fetchAllArticles()
        },
        methods: {
            handleClick(action, item) {
                this.$router.push(`/articles/${item.id}/${action}`)
            },
            onDelete(item) {
                articleService.deleteArticleById(item.id).then(() => {
                    this.fetchAllArticles()
                })
            },
            createArticle() {
                this.$router.push(`/articles/create`)
            },
            fetchAllArticles() {
                articleService.getAllArticles().then(resp => {
                    this.articles = resp.data.data
                })
            },
            mapTrueFalse(value) {
                if (value) {
                    return 'Yes'
                }
                return 'No'
            },
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            }
        }
    }
</script>

<style scoped>
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
