import newsApi from '@/utils/news-api'

export default {
    getAllArticles() {
        return newsApi.get('article/all')
    },

    getArticleById(id) {
        return newsApi.get(`article/${id}`)
    },

    createArticle(data) {
        return newsApi.post('article/new', data)
    },

    editArticleById(id, data) {
        return newsApi.post(`article/${id}/edit`, data)
    },

    deleteArticleById(id) {
        return newsApi.delete(`article/${id}`)
    }

}
