import newsApi from '@/utils/news-api'

export default {
    getAllNewspapers() {
        return newsApi.get(`newspaper/all`)
    },

    getNewspaperById(id) {
        return newsApi.get(`newspaper/${id}`)
    },

    createNewspaper(data) {
        return newsApi.post('newspaper/', data)
    },

    editNewspaperById(id, data) {
        return newsApi.post(`newspaper/${id}/edit`, data)
    }
}
