<template>
    <v-container>
        <v-btn class="my-2" dark small color="#027a00" @click="createNewspaper">
            <v-icon dark>mdi-plus</v-icon>Create
        </v-btn>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="newspapers"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ formatDate(props.item.releaseDate, 'yyyy-MM-DD') }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <!-- <delete-button @delete-entry="onDelete(props.item)" /> -->
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import newspaperService from '@/services/newspaper-service'
    import moment from 'moment'
    // import DeleteButton from '@/components/DeleteButton'
    export default {
        name: 'NewspaperRoute',
        // components: { DeleteButton },
        data: () => {
            return {
                newspapers: [],
                headers: [{
                    text: 'Release Date',
                    value: 'releaseDate',
                    align: 'left',
                    sortable: true
                }
                ]
            }
        },
        created() {
            this.fetchAllNewspapers()
        },
        methods: {
            handleClick(action, item) {
                this.$router.push(`/newspaper/${item.id}/${action}`)
            },
            // onDelete(item) {
            //     newspaperService.deleteArticleById(item.id).then(() => {
            //         this.fetchAllArticles()
            //     })
            // },
            createNewspaper() {
                this.$router.push(`/newspaper/create`)
            },
            fetchAllNewspapers() {
                newspaperService.getAllNewspapers().then(resp => {
                    this.newspapers = resp.data.data
                })
            },
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            }
        }
    }
</script>

<style scoped>
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
